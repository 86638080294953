import styled from "styled-components";

let S = {};
S.CheckboxContainer = styled.div`
  width: 100%;

  & button {
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    color: var(--primarycolor);
    border: none;
    background: none;

    &:hover,
    &:focus,
    &:active {
      color: var(--primarycolor);
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      & > span {
        padding-left: 7.5px;
      }
    }
  }
`;

S.Note = styled.div`
  & > a {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0;
    font-size: 0.9rem;
  }
`;

export default S;
