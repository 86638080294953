import styled from "styled-components";
let S = {};

S.Container = styled.div`
  &.invisible {
    visibility: hidden;
  }

  & label span.error {
    color: var(--error);
  }

  & input {
    margin: 0;
    width: 100%;
    border: 1px solid var(--border);
    border-radius: 2px;
    padding: 5.5px 7.5px;
    box-shadow: var(--boxshadow);
    background-color: #fff;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    font-family: inherit;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border-color: var(--secondarycolor);
    }

    &.error {
      border-color: var(--error);
    }
  }
`;

export default S;
