import styled from "styled-components";

export const Table = styled.table`
  margin: 0;
  padding: 0;
  width: 100%;
  border-collapse: collapse;

  & th,
  td {
    padding: 7.5px;
    border: 1px solid #ddd;
  }

  & th {
    background-color: var(--bg);
  }

  td {
    text-align: left;
    vertical-align: center;
  }

  & th.right,
  td.right {
    text-align: right;
  }

  & th.left {
    text-align: left;
  }

  & td.center {
    text-align: center;
  }
`;

export const Form = styled.form`
  margin: 0;
  padding: 0;
  margin-top: 25px;
  padding: 15px 25px 25px 25px;
  width: 100%;
  background: var(--bg);

  & label {
    font-size: 0.9rem;
    line-height: 22px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    display: inline-block;
    margin-bottom: 3px;
  }

  & > div:not(:first-child) {
    margin-top: 15px;
  }
`;

export const FormRow1 = styled.div`
  width: 100%;
`;

export const FormRow2 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;

  &.hidden {
    display: none;
  }

  @media only screen and (max-width: 649px) {
    grid-template-columns: 1fr;
  }

  &.first-smaller {
    grid-template-columns: 0.4fr 1fr;

    @media only screen and (max-width: 649px) {
      grid-template-columns: 1fr;
    }
  }

  & div.empty {
    display: none;
  }
`;

export const FormRow3 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 0.65fr 1fr;
  grid-gap: 15px;

  @media only screen and (max-width: 649px) {
    grid-template-columns: 1fr;
  }

  & div.empty {
    display: none;
  }
`;

export const FormMsgSubmit = styled.div`
  padding-top: 7.5px;
  width: 100%;
`;
