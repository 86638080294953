import { useState, useEffect, Fragment } from "react";
import { API_URL, getValFromLS, setValToLS } from "../helpers";
import useFetch from "../useFetch";
import Alert from "../components/Alert";
import S from "./styled";

const Receipt = ({ status }) => {
	const [url, setUrl] = useState(null);
	const [details, setDetails] = useState({});
	const [alert, setAlert] = useState({});

	const { loading, data } = useFetch(url);

	useEffect(() => {
		const newUrl = new URL(`${API_URL}/receiptInit`);
		const params = {
			id: getValFromLS("receipt", true) ? getValFromLS("receipt", true) : "",
		};
		newUrl.search = new URLSearchParams(params);
		setUrl(newUrl);

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (data && data.resp === 1) {
			setDetails({ ...data });
			setValToLS("receipt", null, true);
		} else if (data && data.resp === 0) {
			status(2);
		}

		// eslint-disable-next-line
	}, [data]);

	return (
		<Fragment>
			{loading || alert.type ? (
				<Alert
					data={
						loading
							? {
									type: "working",
									text: "",
							  }
							: alert
					}
				/>
			) : (
				<S.Container>
					<Alert
						data={{
							type: "success",
							text: "Your order has been submitted, thank you!",
						}}
					/>

					<S.Hdg>
						<h4>Order Receipt</h4>

						<p>
							A copy of this order has been emailed to{" "}
							<strong>{details.email}</strong>, please remember to check your
							spam folder if not received in a few minutes.
						</p>
					</S.Hdg>

					<S.Table1>
						<thead>
							<tr>
								<th>Name</th>
								<th>Order ID</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className="center">{details.name}</td>
								<td className="center">{details.id}</td>
								<td className="center">{details.date}</td>
							</tr>
						</tbody>
					</S.Table1>

					<S.Note>
						<p>
							Thank you so much for your most generous contribution in support
							of LIFE’s 31st Annual “Lady in Red” Gala to be held on March 2,
							2023 at The Breakers in Palm Beach.
						</p>

						<p>
							Proceeds will benefit Disabled Veterans through American Humane’s
							“Pups4Patriots” Program and South Florida youth through Palm Beach
							County Food Bank’s Lois’ Food4Kids Program.
						</p>

						<p>
							You will receive a separate personalized letter from Leaders In
							Furthering Education, Inc. (LIFE) regarding tax benefits related
							to your contribution.
						</p>

						<p>
							Sincerely,
							<br />
							<br />
							Lois Pope
						</p>
					</S.Note>

					<S.Table2>
						<thead>
							<tr>
								<th className="qty">Qty</th>
								<th className="left">Item</th>
								<th>Amount</th>
							</tr>
						</thead>
						<tbody>
							{details.items.map((item) => {
								return (
									<tr key={item.id}>
										<td className="qty center">
											<span>{item.qty}</span>
										</td>
										<td>
											<div>
												<span>{item.name}</span>
												<small>{`${item.each} ea.`}</small>
											</div>
										</td>
										<td className="right">{item.amount}</td>
									</tr>
								);
							})}
						</tbody>
						<tfoot>
							<tr>
								<th colSpan="2" className="right">
									TOTAL
								</th>
								<th className="right">{details.total}</th>
							</tr>
							<tr>
								<th colSpan="2" className="right">
									{details.payment.description}
								</th>
								<th className="right">{details.payment.amount}</th>
							</tr>
						</tfoot>
					</S.Table2>
				</S.Container>
			)}
		</Fragment>
	);
};

export default Receipt;
