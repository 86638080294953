import { useState } from "react";
import { isFieldError, createMarkup } from "../../helpers";
import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faCheckSquare } from "@fortawesome/pro-light-svg-icons";

const Checkbox = ({ name, label, reqd, update, note, errors }) => {
  const [checked, setChecked] = useState(false);

  const handleClick = (name) => {
    setChecked(!checked);
    update(name, !checked);
  };

  return (
    <div>
      <S.CheckboxContainer
        className={isFieldError(errors, name) ? `error` : ``}
      >
        <button type="button" onClick={(e) => handleClick(name)}>
          <div>
            <FontAwesomeIcon
              icon={checked ? faCheckSquare : faSquare}
              size="lg"
            />
            {label && (
              <span>
                {" "}
                {`${label}`} {reqd ? <span className="error">*</span> : null}
              </span>
            )}
          </div>
        </button>
      </S.CheckboxContainer>
      {note && <S.Note dangerouslySetInnerHTML={createMarkup(note)} />}
    </div>
  );
};
export default Checkbox;
