import styled from "styled-components";
import {
  Form,
  FormRow1,
  FormRow2,
  FormRow3,
  FormMsgSubmit,
  Table as BaseTable,
} from "../styled";

let S = {};
S.Form = Form;
S.FormRow1 = FormRow1;
S.FormRow2 = FormRow2;
S.FormRow3 = FormRow3;
S.FormMsgSubmit = FormMsgSubmit;

S.Container = styled.div`
  margin: 0 auto;
  padding: 0;
  padding: 0 7.5%;

  @media only screen and (max-width: 767px) {
    padding: 0 5%;
  }

  @media only screen and (max-width: 649px) {
    padding: 0;
  }
`;

S.Hdg = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;

  & h4 {
    margin: 0;
    padding: 0;
    font-size: 1.1rem;
    line-height: 28px;
  }

  & p {
    margin: 0;
    padding: 0;
    margin-top: 2.5px;
    line-height: 20px;
  }
`;

S.Table1 = styled(BaseTable)`
  margin-bottom: 25px;

  & th {
    width: 33%;
  }

  & th:nth-child(2) {
    width: 34%;
  }

  & td {
    padding: 15px 7.5px;
  }
`;

S.Table2 = styled(BaseTable)`
  margin-top: 25px;

  & th.qty {
    width: 15%;

    @media only screen and (max-width: 649px) {
      width: 30%;
    }
  }

  & td {
    padding: 10px 7.5px;
    background: #fff;
  }

  & td span {
    display: block;
    font-size: 1rem;
  }

  & td div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 649px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    & small {
      font-size: 0.9rem;
      line-height: 20px;
    }
  }

  & tfoot th {
    background: #fff;
    font-weight: normal;
  }

  & tfoot tr:last-child th {
    padding: 15px 7.5px;
    font-size: 1rem;
    font-weight: bold;
  }
`;

S.Note = styled.div`
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  padding: 7.5px 15px;

  & > p {
    line-height: 23px;
  }
`;

export default S;
