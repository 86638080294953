import { useState, useEffect } from "react";

const useFetch = (url) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (!url) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(url, {
          method: "GET",
          cache: "no-store",
        });
        const json = await response.json();
        setData({ ...json });
      } catch (error) {
        setData({ resp: 0, text: "An error occurred loading data." });
      }
      setLoading(false);
    };

    fetchData();
  }, [url]);

  return { loading, data };
};

export default useFetch;
