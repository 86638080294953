import styled from "styled-components";

let S = {};
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;

  &.inline {
    margin-top: 5px;
    margin-bottom: 25px;
    flex-direction: row;
    font-size: 1rem;
    padding-left: 15px;
    padding-right: 15px;
  }

  &.working {
    color: var(--working);
  }

  &.success {
    color: var(--success);
  }

  &.notice {
    color: var(--notice);
  }

  &.error {
    color: var(--error);
  }

  & > span {
    padding-top: 15px;
  }

  &.inline > span {
    padding-top: 0;
    padding-left: 10px;
  }
`;

export default S;
