import { isFieldError } from "../../helpers";
import S from "./styled";

const Input = ({
  type = "text",
  name,
  label,
  reqd,
  autocomplete = name,
  update,
  errors,
  visible = true,
  value = "",
}) => {
  return (
    <S.Container className={visible ? `` : `invisible`}>
      <label htmlFor={name}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        autoComplete={autocomplete}
        onChange={(e) => update(name, e.target.value)}
        className={isFieldError(errors, name) ? `error` : ``}
        defaultValue={value}
      />
    </S.Container>
  );
};
export default Input;
