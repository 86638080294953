import { useState, Fragment } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { STRIPE_KEY } from "./helpers";
import Alert from "./components/Alert";
import Order from "./Order";
import Receipt from "./Receipt";

const stripePromise = loadStripe(STRIPE_KEY);

const App = () => {
  const [status, setStatus] = useState(2);

  /*
    0 - error
    1 - payment submitted successfully
    2 - order not submitted
  */

  const handleStatus = (status) => {
    setStatus(status);
  };

  return (
    <Fragment>
      {status === 0 ? (
        <Alert />
      ) : status === 1 ? (
        <Receipt status={handleStatus} />
      ) : (
        <Elements stripe={stripePromise}>
          <Order status={handleStatus} />
        </Elements>
      )}
    </Fragment>
  );
};

export default App;
