export const API_URL = "https://www.life-edu.org/api";

/*
  pk_test_51JoqAgEgsrfFuMvrnkFL0OE5jYvSo27OdWmyBsxXiLox2Mw7rxfMq9LkPAmTFYGGZF7UFAH9sMuXYxWtw9T4HbCC00rlDPdvUH // test publishable key
  pk_live_51JoqAgEgsrfFuMvr9PRDG2PBgF74YkiHkrqhG6LEjzAQrVXOYf40vU7JTPKzJztXH6HNKYXR37mIUHZLgIbi8ewg00ydOGzNsX // live publishable key
*/
export const STRIPE_KEY =
	"pk_live_51JoqAgEgsrfFuMvr9PRDG2PBgF74YkiHkrqhG6LEjzAQrVXOYf40vU7JTPKzJztXH6HNKYXR37mIUHZLgIbi8ewg00ydOGzNsX";

export function isFieldError(array, val) {
	return array.includes(val);
}

export function createMarkup(html) {
	return { __html: html };
}

const isBrowser = () => typeof window !== "undefined";

export const getValFromLS = (key, useSessionStorage = false) => {
	if (useSessionStorage) {
		return isBrowser() && window.sessionStorage.getItem(key)
			? window.sessionStorage.getItem(key)
			: undefined;
	} else {
		return isBrowser() && window.localStorage.getItem(key)
			? window.localStorage.getItem(key)
			: undefined;
	}
};

export const setValToLS = (key, val, useSessionStorage = false) => {
	if (isBrowser() && val) {
		useSessionStorage
			? window.sessionStorage.setItem(key, val)
			: window.localStorage.setItem(key, val);
	} else {
		useSessionStorage
			? window.sessionStorage.removeItem(key)
			: window.localStorage.removeItem(key);
	}
	return true;
};

export function fmtCurrency(int, country = "en-US", symbol = "USD") {
	const amt = int;
	return `${amt.toLocaleString(country, {
		style: "currency",
		currency: symbol,
	})}`;
}
