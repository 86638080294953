import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { isFieldError } from "../../helpers";
import S from "./styled";

const InputStripe = ({ name, label, reqd, errors = [] }) => {
  return (
    <S.Container
      className={isFieldError(errors, name) ? "stripe border-error" : "stripe"}
    >
      <label htmlFor={name}>
        {`${label}`} {reqd ? <span className="error">*</span> : null}
      </label>
      <CardElement />
    </S.Container>
  );
};
export default InputStripe;
