import styled from "styled-components";

let S = {};
S.Container = styled.div`
  margin-bottom: 5px;

  & button {
    margin: 0;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    color: var(--primarycolor);
    border-color: var(--primarycolor);
    border-width: 1px;
    padding: 8px;
    border-radius: 3px;
    width: 100%;
    background-color: var(--primarycolor);
    color: #fff;
    font-weight: bold;
    border-left-style: solid;
    border-right-style: solid;
    border-top-style: solid;
    border-bottom-style: solid;
    outline: none;

    &:hover,
    &:active,
    &:focus,
    &:visited {
      color: var(--primarycolor);
      background-color: #fff;
    }

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;

      & > span {
        padding-right: 7.5px;
        text-transform: uppercase;
        letter-spacing: 0.5px;
      }
    }
  }
`;

export default S;
