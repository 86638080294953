import S from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faTimesCircle,
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";

const Alert = ({ data }) => {
  return (
    <S.Container
      className={data.inline ? `${data.type} inline` : `${data.type}`}
    >
      {data.type === "working" ? (
        <FontAwesomeIcon icon={faCircleNotch} size="lg" spin />
      ) : data.type === "success" ? (
        <FontAwesomeIcon icon={faCheckCircle} size="lg" />
      ) : data.type === "notice" ? (
        <FontAwesomeIcon icon={faExclamationTriangle} size="lg" />
      ) : (
        <FontAwesomeIcon icon={faTimesCircle} size="lg" />
      )}
      {data.text && <span>{data.text}</span>}
    </S.Container>
  );
};

export default Alert;
