import styled from "styled-components";

let S = {};

S.Container = styled.div`
  &.stripe .StripeElement {
    margin: 0;
    width: 100%;
    border: 1px solid var(--border);
    border-radius: 2px;
    vertical-align: middle;
    padding: 9.5px 7.5px;
    background-color: #fff;
    box-shadow: var(--boxshadow);
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    font-family: inherit;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &.stripe .StripeElement--focus {
    border-color: var(--secondarycolor);
  }

  &.stripe.border-error .StripeElement,
  &.stripe .StripeElement--invalid {
    border-color: var(--error);
  }

  &.stripe .is-invalid {
    color: var(--error);
  }
`;

export default S;
