import styled from "styled-components";

let S = {};
S.Container = styled.div`
  margin-top: 7.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &.working {
    color: var(--working);
  }

  &.error {
    color: var(--error);
  }

  &.success {
    color: var(--success);
  }

  & > span {
    padding-top: 7.5px;
    padding-bottom: 15px;
  }
`;

export default S;
