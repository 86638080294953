import { useState, useEffect, useRef } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import { API_URL, setValToLS, fmtCurrency } from "../helpers";

import useFetch from "../useFetch";

import Alert from "../components/Alert";
import Checkbox from "../components/Checkbox";
import Input from "../components/Input";
import InputStripe from "../components/InputStripe";
import Msg from "../components/Msg";
import Submit from "../components/Submit";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faMinus,
	faChevronCircleRight,
} from "@fortawesome/pro-light-svg-icons";

import S from "./styled";

function getObject(array, id) {
	let obj;
	array.forEach((item) => {
		if (item.id === id) {
			obj = {
				id: item.id,
				name: item.name,
				amt: item.amt,
			};
		}
	});
	return obj;
}

const Order = ({ status }) => {
	const [url, setUrl] = useState(null);
	const [tickets, setTickets] = useState([]);
	const [order, setOrder] = useState([]);
	const [totals, setTotals] = useState({
		total: 0,
	});
	const [names, setNames] = useState({});
	const [errors, setErrors] = useState([]);
	const [msg, setMsg] = useState({});
	const [msg2, setMsg2] = useState({});
	const [alert, setAlert] = useState({});

	const { loading, data } = useFetch(url);
	const formElement = useRef(null);
	const formElement2 = useRef(null);
	const stripe = useStripe();
	const elements = useElements();

	useEffect(() => {
		const newUrl = new URL(`${API_URL}/orderInit`);
		setUrl(newUrl);

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (data && data.resp === 1) {
			setTickets([...data.tickets]);
		}
	}, [data]);

	const handleCheckbox = (name, value) => {
		let newOrder = order;
		if (value) {
			const obj = getObject(tickets, name);

			newOrder.push({
				id: obj.id,
				name: obj.name,
				qty: 1,
				amt: obj.amt,
			});
		} else {
			let foundIndex;
			newOrder.forEach((item, index) => {
				if (item.id === name) {
					foundIndex = index;
				}
			});
			newOrder.splice(foundIndex, 1);
		}

		let total = 0;
		newOrder.forEach((item) => {
			total = total + item.qty * item.amt;
		});
		setOrder([...newOrder]);
		setTotals({
			total,
		});
		handleUpdate(name, value);
		if (name === "donation") {
			setMsg2({});
		}
	};

	const handlePlus = (index) => {
		let newOrder = order;
		let newQty = newOrder[index].qty + 1;
		newOrder[index].qty = newQty;

		let total = 0;
		newOrder.forEach((item) => {
			total = total + item.qty * item.amt;
		});
		setOrder([...newOrder]);
		setTotals({
			total,
		});
	};

	const handleMinus = (index) => {
		let newOrder = order;
		let newQty = newOrder[index].qty - 1;
		if (newQty < 1) {
			newQty = 1;
		}
		newOrder[index].qty = newQty;

		let total = 0;
		newOrder.forEach((item) => {
			total = total + item.qty * item.amt;
		});
		setOrder([...newOrder]);
		setTotals({
			total,
		});
	};

	const handleUpdate = (name, value) => {
		setNames((names) => ({ ...names, [name]: value ? value : "" }));
	};

	const handleSubmit2 = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg2({
			type: "working",
			text: "",
		});

		if (!names.other) {
			setErrors(["other"]);
			setMsg2({
				type: "error",
				text: "Please enter a valid dollar amount for donation.",
			});

			return false;
		}

		const other = names.other.replace(/\$/g, "");

		if (other && !isNaN(parseFloat(other))) {
			let newOrder = order;
			const obj = getObject(tickets, "donation");

			let foundIndex;
			newOrder.forEach((item, index) => {
				if (item.id === "donation") {
					foundIndex = index;
				}
			});
			newOrder[foundIndex] = {
				id: obj.id,
				name: obj.name,
				qty: 1,
				amt: parseFloat(other) * 100,
			};

			let total = 0;
			newOrder.forEach((item) => {
				total = total + item.qty * item.amt;
			});
			setOrder([...newOrder]);
			setTotals({
				total,
			});

			setMsg2({
				type: "success",
				text: "Donation added to your order.",
			});
		} else {
			setErrors(["other"]);
			setMsg2({
				type: "error",
				text: "Please enter a valid dollar amount for donation.",
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrors([]);
		setMsg({
			type: "working",
			text: "",
		});

		const url = new URL(`${API_URL}/order`);
		let formData = new FormData();
		Object.entries(names).forEach(([key, value]) => {
			if (key === "donation") {
				formData.append(key, value ? "1" : "0");
			} else {
				formData.append(key, value);
			}
		});
		formData.append("order", JSON.stringify(order));

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				handlePayment();
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	const handlePayment = async () => {
		try {
			const result = await stripe.createPaymentMethod({
				type: "card",
				card: elements.getElement(CardElement),
				billing_details: {
					name: `${names.fname} ${names.lname}`,
					email: `${names.email}`,
				},
			});

			if (result.error) {
				setMsg({
					type: "error",
					text: result.error.message,
				});
			} else {
				handleCreated(result.paymentMethod);
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "Please enter a payment method.",
			});
			setErrors(["card"]);
		}
	};

	const handleCreated = async (paymentMethod) => {
		const url = new URL(`${API_URL}/submit`);
		let formData = new FormData();
		Object.entries(names).forEach(([key, value]) =>
			formData.append(key, value)
		);
		formData.append("order", JSON.stringify(order));
		formData.append("total", totals.total);
		formData.append("stripePaymentMethod", JSON.stringify(paymentMethod));

		try {
			const response = await fetch(url, {
				method: "POST",
				body: formData,
			});
			const json = await response.json();
			if (json && json.resp === 1) {
				setValToLS("receipt", json.id, true);
				status(1);
			} else {
				setErrors(json.fields);
				setMsg({
					type: "error",
					text: json.text,
				});
			}
		} catch (error) {
			setMsg({
				type: "error",
				text: "An error has occurred.",
			});
		}
	};

	return (
		<>
			{loading || alert.type ? (
				<Alert
					data={
						loading
							? {
									type: "working",
									text: "",
							  }
							: alert
					}
				/>
			) : (
				<>
					{/*<S.TopContainer>
						<div className="grid1">
							<div>
								<h3>GALA CHAIRS</h3>
								<p>
									Lois Pope
									<br />
									Christine Lynn
								</p>
							</div>
							<div>
								<h3>GRAND HONORARY CHAIR</h3>
								<p>Tova Leidesdorf</p>
							</div>
							<div>
								<h3>HONORARY CHAIRS</h3>
								<p>Gail Worth & Frank Orenstein</p>
							</div>
							<div>
								<h3>CO-CHAIRS</h3>
								<p>
									Dr. Robin Ganzert
									<br />
									Marti LaTour
								</p>
							</div>
						</div>
						<div className="grid1" style={{ marginTop: "15px" }}>
							<div>
								<h3>INSPIRATIONAL CHAIRS</h3>
								<p>
									Holly & David Dreman
									<br />
									Caroline Harless
									<br />
									Ari Rifkin
								</p>
							</div>
							<div>
								<h3>ENTERTAINMENT CHAIR</h3>
								<p>Sunny Sessa</p>
							</div>
						</div>

						<div className="grid2" style={{ marginTop: "15px" }}>
							<div>
								<h3>CORPORATE AMBASSADORS</h3>
								<p>
									Sally Kimball, President & Founder, Ambassador Realty
									Corporation
									<br />
									Moti Ferder, Co-Founder & CEO, Lugano Diamonds
									<br />
									Christiane Ouvier, Boutique Director, Van Cleef & Arpels
									<br />
									Walter J. Schwenk, AAMS, Managing Director - Investment
									Officer, Wells Fargo Advisors
									<br />
								</p>
							</div>
						</div>
			</S.TopContainer>*/}

					<S.Grid>
						<S.LeftContainer>
							<h3>BOARD OF DIRECTORS</h3>
							<p>
								Lois Pope, <i>President</i>
							</p>
							<p>
								Jayne M. Kurzman, Esq., <i>Vice President and Secretary</i>
							</p>

							<p>
								Elsa G. Johnson, <i>Treasurer</i>
							</p>
							<p>Simone Bonutti</p>
							<p>John D. Deese</p>
							<p>Robin R. Ganzert, Ph.D.</p>
							<p>Marti M. LaTour</p>
							<p>Christine E. Lynn</p>
							<p>Frank Orenstein</p>
							<p>Patrick M. Park</p>
							<p>Ari Rifkin</p>
							<p>Erika Rizzuto</p>
							<p>Walter Schwenk</p>
							<p>Sunny Sessa</p>
							<h3>ADVISORY BOARD</h3>
							<p>Dawn Assenzio</p>
							<p>Sandra K. Baker</p>
							<p>Maude Emerson Cook</p>
							<p>Holly Dreman</p>
							<p>Suzi A. Goldsmith</p>
							<p>Caroline O. Harless</p>
							<p>Hayden Hosford</p>
							<p>Sharon Jablin</p>
							<p>Dr. Gary Johnson, MD</p>
							<p>Tamara H. Karu</p>
							<p>Maria Pope Kessel</p>
							<p>Kerry R. Kessel</p>
							<p>Sally Kimball</p>
							<p>Steven S. Locante</p>
							<p>Joseph McNamara</p>
							<p>Max McNamara</p>
							<p>John B. Payne</p>
							<p>Brooke E. Samples</p>
							<p>Florence Seiler</p>
							<p>Gail K. Worth</p>
						</S.LeftContainer>

						<S.Container>
							{/*<Alert
            data={{
              inline: true,
              type: "notice",
              text: "The 30th Annual Lady in Red Gala is almost sold out.",
            }}
          />*/}

							<S.Details>
								<img
									src="https://life-edu.org/assets/lady-in-red-tickets.png"
									alt="Lady in Red"
								/>

								<div>
									<p>31st Annual Lady in Red Gala</p>

									<p
										style={{
											marginTop: "0",
											marginBottom: "15px",
											fontSize: "1.05rem",
										}}
									>
										<i>
											Reserve your tables and sponsorships early for what
											promises to be a truly historic event.
										</i>
									</p>

									<p
										style={{
											fontSize: "1.15rem",
											display: "none",
										}}
									>
										Starring Global Superstar, Paul Anka & Comedy Icon, Jay Leno
									</p>

									<ul>
										<li>Sunday, March 2, 2025</li>
										<li>The Breakers, Palm Beach</li>
										<li>6:00 PM</li>
										<li>Gala Attire</li>
									</ul>

									<p>
										RSVP by February 4, 2025{" "}
										{/*Please note: tickets to the 2025
								Gala are almost sold out.*/}
									</p>
								</div>
							</S.Details>

							<p style={{ textAlign: "center" }}>
								To learn more about how to attend or donate please call LIFE at
								561.582.8083 or email{" "}
								<a href="mailto:life@life-edu.org">life@life-edu.org</a>.
							</p>

							<S.Table1>
								<thead>
									<tr>
										<th>Select</th>
										<th className="left">Item</th>
										<th>Price</th>
									</tr>
								</thead>
								<tbody>
									{tickets.map((item) => {
										return (
											<tr key={item.id}>
												<td>
													<Checkbox
														name={item.id}
														label=""
														reqd={false}
														autocomplete="off"
														update={handleCheckbox}
														note=""
														errors={errors}
													/>
												</td>
												<td>
													<h4>{item.name}</h4>
													{item.bullets.length > 0 && (
														<ul>
															{item.bullets.map((bullet, index) => {
																return <li key={index}>{bullet}</li>;
															})}
														</ul>
													)}
												</td>
												<td className="center">
													{item.amt > 0
														? fmtCurrency(item.amt / 100, "en-US", "USD")
														: ""}
												</td>
											</tr>
										);
									})}
								</tbody>
							</S.Table1>

							{names.donation && (
								<S.Form
									method="post"
									action="/"
									onSubmit={(e) => handleSubmit2(e)}
									ref={formElement2}
								>
									<S.FormRow2
										className={msg2.type === "success" ? "hidden" : ""}
									>
										<Input
											name="other"
											label="Enter Donation Amount"
											reqd={true}
											autocomplete="off"
											update={handleUpdate}
											errors={errors}
										/>

										<div className="empty" />
									</S.FormRow2>

									<S.FormMsgSubmit>
										{msg2.type && <Msg data={msg2} />}
										{msg2.type !== "working" && msg2.type !== "success" && (
											<Submit name="Add Donation" icon={faChevronCircleRight} />
										)}
									</S.FormMsgSubmit>
								</S.Form>
							)}

							{order.length === 0 ? (
								<div style={{ marginTop: "35px", marginBottom: "30px" }}>
									<Alert
										data={{
											type: "notice",
											text: "Please select a ticket option above to place order.",
										}}
									/>
								</div>
							) : (
								<S.Form
									method="post"
									action="/"
									onSubmit={(e) => handleSubmit(e)}
									ref={formElement}
								>
									<S.Hdg>
										<h4>
											Fill out the form below to purchase ticket(s) to the 2025
											Lady In Red Gala.
										</h4>

										<p>Sunday, March 2, 2025 - The Breakers, Palm Beach</p>
									</S.Hdg>

									<S.FormRow2>
										<Input
											name="fname"
											label="First Name"
											reqd={true}
											autocomplete="given-name"
											update={handleUpdate}
											errors={errors}
										/>

										<Input
											name="lname"
											label="Last Name"
											reqd={true}
											autocomplete="family-name"
											update={handleUpdate}
											errors={errors}
										/>
									</S.FormRow2>

									<S.FormRow2>
										<Input
											name="email"
											label="Email Address"
											reqd={true}
											autocomplete="email"
											update={handleUpdate}
											errors={errors}
										/>

										<Input
											name="phone"
											label="Phone"
											reqd={true}
											autocomplete="tel"
											update={handleUpdate}
											errors={errors}
										/>
									</S.FormRow2>

									<S.FormRow2>
										<Input
											name="address"
											label="Address"
											reqd={true}
											autocomplete="address-line1"
											update={handleUpdate}
											errors={errors}
										/>

										<Input
											name="address2"
											label="Address 2"
											reqd={false}
											autocomplete="address-line2"
											update={handleUpdate}
											errors={errors}
										/>
									</S.FormRow2>

									<S.FormRow3>
										<Input
											name="city"
											label="City"
											reqd={true}
											autocomplete="address-level2"
											update={handleUpdate}
											errors={errors}
										/>

										<Input
											name="st"
											label="State (2-letter)"
											reqd={true}
											autocomplete="address-level1"
											update={handleUpdate}
											errors={errors}
										/>

										<Input
											name="zip"
											label="Zip Code"
											reqd={true}
											autocomplete="postal-code"
											update={handleUpdate}
											errors={errors}
										/>
									</S.FormRow3>

									<S.Table2>
										<thead>
											<tr>
												<th className="qty">Qty</th>
												<th className="left">Item</th>
												<th>Amount</th>
											</tr>
										</thead>
										<tbody>
											{order.map((item, index) => {
												const subtotal = item.qty * item.amt;

												return (
													<tr key={item.id}>
														<td className="qty center">
															<div>
																<button
																	type="button"
																	onClick={() => handleMinus(index)}
																>
																	<FontAwesomeIcon icon={faMinus} size="1x" />
																</button>
																<span>{item.qty}</span>
																<button
																	type="button"
																	onClick={() => handlePlus(index)}
																>
																	<FontAwesomeIcon icon={faPlus} size="1x" />
																</button>
															</div>
														</td>
														<td>
															<h4>{item.name}</h4>
															<ul>
																<li>{`${fmtCurrency(
																	item.amt / 100,
																	"en-US",
																	"USD"
																)} ea.`}</li>
															</ul>
														</td>
														<td className="right">
															{fmtCurrency(subtotal / 100, "en-US", "USD")}
														</td>
													</tr>
												);
											})}
										</tbody>
										<tfoot>
											<tr>
												<th colSpan="2" className="right">
													TOTAL
												</th>
												<th className="right">
													{fmtCurrency(totals.total / 100, "en-US", "USD")}
												</th>
											</tr>
										</tfoot>
									</S.Table2>

									<S.FormRow1>
										<InputStripe
											name="card"
											label="Credit or Debit Card"
											reqd={true}
											errors={errors}
										/>
									</S.FormRow1>

									<S.Note>
										<p>
											Contributions in excess of $750 per ticket are tax
											deductible as permitted by law.
										</p>
										<p>
											A copy of the official registration and financial
											information may be obtained from the Division of Consumer
											Services by calling toll-free, within the state,
											1-800-435-7352. Registration does not imply endorsement,
											approval or recommendation by the state.
										</p>

										<p>
											All charges will appear as <strong>LIFE DONATION</strong>{" "}
											on your credit card statement.
										</p>

										<p>
											<strong>
												<i>Proceeds benefit:</i>
											</strong>{" "}
											Disabled Veterans through American Humane’s
											“Pups4Patriots” Program and South Florida youth through
											Palm Beach County Food Bank’s Lois’ Food4Kids Program.
										</p>
									</S.Note>

									<S.FormMsgSubmit>
										{msg.type && <Msg data={msg} />}
										{msg.type !== "working" && (
											<Submit
												name="Submit Ticket Purchase"
												icon={faChevronCircleRight}
											/>
										)}
									</S.FormMsgSubmit>
								</S.Form>
							)}

							<p style={{ marginTop: "15px", textAlign: "center" }}>
								For additional information, please call (561) 582-8083 or email:{" "}
								<a href="mailto:life@life-edu.org">life@life-edu.org</a>.
							</p>
						</S.Container>
					</S.Grid>
				</>
			)}
		</>
	);
};

export default Order;
