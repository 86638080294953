import styled from "styled-components";
import {
	Form,
	FormRow1,
	FormRow2,
	FormRow3,
	FormMsgSubmit,
	Table as BaseTable,
} from "../styled";

let S = {};
S.Form = Form;
S.FormRow1 = FormRow1;
S.FormRow2 = FormRow2;
S.FormRow3 = FormRow3;
S.FormMsgSubmit = FormMsgSubmit;

S.TopContainer = styled.div`
	margin-bottom: 15px;
	border-bottom: 1px solid var(--border);
	padding-bottom: 15px;

	& > div {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
		justify-items: center;

		& > div {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
		}
	}

	& > .grid2 {
		grid-template-columns: 1fr;
	}

	& h3 {
		color: var(--red);
		margin: 0;
		margin-top: 15px;
		font-size: 1rem;
		line-height: 22px;
	}

	& p {
		margin: 0;
		line-height: 22px;
	}
`;

S.Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-gap: 0;

	@media only screen and (max-width: 967px) {
		grid-template-columns: 1fr;
	}
`;

S.LeftContainer = styled.div`
	padding-left: 15px;

	@media only screen and (max-width: 967px) {
		order: 2;
		padding-left: 0;
	}

	& > h3 {
		color: var(--red);
		margin: 0;
		margin-top: 15px;
		font-size: 1rem;
		line-height: 22px;
	}

	& > p {
		margin: 0;
	}
`;

S.Container = styled.div`
	margin: 0 auto;
	padding: 0;
	padding: 0 7.5%;

	@media only screen and (max-width: 767px) {
		padding: 0 5%;
	}

	@media only screen and (max-width: 649px) {
		padding: 0;
	}
`;

S.Details = styled.div`
	width: 100%;
	padding: 0;
	margin: 0 auto;
	margin-top: 15px;
	margin-bottom: 25px;
	display: flex;
	align-items: center;
	border: 1px solid #ddd;
	padding-left: 25px;
	padding-right: 25px;
	padding-top: 15px;

	& > img {
		display: block;
		margin: 0;
		padding: 0;
		border: none;
		width: 150px;
		height: auto;

		@media only screen and (max-width: 649px) {
			display: none;
		}
	}

	& > div {
		margin-left: 25px;

		@media only screen and (max-width: 649px) {
			margin-left: 0;
		}

		& > p {
			text-align: center;
			margin: 0;
			padding: 0;
			font-size: 16px;
			line-height: 20px;
		}

		& > p:first-child {
			font-size: 1.5rem;
			color: var(--red);
			font-weight: bold;
			margin-bottom: 10px;
		}

		& > ul {
			margin: 0;
			padding: 0;
			margin-left: 40px;
			margin-top: 15px;
			margin-bottom: 20px;

			& > li {
				line-height: 20px;
				padding-bottom: 2.5px;
			}

			& > li:last-child {
				padding-bottom: 0;
			}
		}

		& > p:last-child {
			font-size: 1.1rem;
			color: var(--red);
			font-weight: bold;

			@media only screen and (max-width: 649px) {
				margin-bottom: 15px;
			}
		}
	}
`;

S.Hdg = styled.div`
	margin-bottom: 20px;

	& h4 {
		margin: 0;
		padding: 0;
		font-size: 1.1rem;
		line-height: 28px;
	}

	& p {
		margin: 0;
		padding: 0;
		margin-top: 2.5px;
		line-height: 20px;
	}
`;

S.Table1 = styled(BaseTable)`
	& td {
		padding: 15px 7.5px;
		vertical-align: middle !important;
	}

	& td h4 {
		margin: 0;
		padding: 0;
		color: var(--red);
		font-size: 1.1rem;
	}

	& td ul {
		margin: 0;
		padding: 0;
		margin-left: 30px;

		& > li {
			line-height: 20px;
			padding-bottom: 2.5px;
		}

		& > li:last-child {
			padding-bottom: 0;
		}
	}
`;

S.Table2 = styled(BaseTable)`
	margin-top: 25px;
	margin-bottom: 25px;

	& th.qty {
		width: 15%;

		@media only screen and (max-width: 649px) {
			width: 30%;
		}
	}

	& td.qty div {
		display: flex;
		align-items: center;
		justify-content: center;

		& button {
			margin: 0;
			padding: 0;
			cursor: pointer;
			width: 25px;
			background: #fff;
			color: var(--primarycolor);
			border: 1px solid #ddd;
			padding: 2px;
			border-radius: 3px;
			box-shadow: none;
			-webkit-appearance: none;

			&:hover {
				border-color: var(--primarycolor);
			}
		}

		& > span {
			display: block;
			padding-left: 7.5px;
			padding-right: 7.5px;
			font-size: 1rem;
		}
	}

	& td {
		padding: 10px 7.5px;
		background: #fff;
		vertical-align: middle !important;
	}

	& td h4 {
		margin: 0;
		padding: 0;
		font-size: 1rem;
		line-height: 28px;
	}

	& td ul {
		margin: 0;
		padding: 0;
		margin-left: 30px;

		& > li {
			padding-bottom: 2.5px;
		}

		& > li:last-child {
			padding-bottom: 0;
		}
	}

	& tfoot th {
		background: #fff;
		padding: 15px 7.5px;
		font-size: 1rem;
	}
`;

S.Note = styled.div`
	width: 100%;
	background: #fff;
	border: 1px solid #ddd;
	padding: 7.5px 15px;

	& > p {
		line-height: 23px;
	}
`;

export default S;
